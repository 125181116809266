import { GatsbySeo } from 'gatsby-plugin-next-seo'

import NewAboutUs from '../../components/Institucional/NewAboutUs'

function AboutDecathlon() {
  return (
    <>
      <GatsbySeo title="Institucional | Decathlon" />
      <NewAboutUs />
    </>
  )
}

export default AboutDecathlon
